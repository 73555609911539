<template>
  <b-modal
    v-model="ownControl"
    scrollable
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    :title="title"
    size="md"
    @hidden="closeModal"
  >
    <validation-observer
      ref="form"
    >
      <validation-provider
        v-slot="{ errors, valid }"
        name="description"
        rules="required"
      >
        <b-form-group
          label="Description:"
          label-for="input-7"
        >
          <b-form-input
            v-model="description"
            type="text"
          />
          <span
            v-if="errors[0]"
            class="text-danger"
          > Description {{ errors[0] }}</span>
        </b-form-group>
      </validation-provider>
    </validation-observer>
    <template #modal-footer="{cancel}">
      <button-save @click="saveFavorite"/>
      <button-cancel @click="cancel()"/>
    </template>
  </b-modal>
</template>

<script>
import buttonSave from '@/views/commons/utilities/ButtonSave.vue'
import buttonCancel from '@/views/commons/utilities/ButtonCancel.vue'
import CompaniesService from "@/views/management/views/companies/companies.service";
import {mapGetters} from "vuex";

export default {
  components: {
    buttonSave,
    buttonCancel,
  },
  props: {
    dest: null,
  },
  data() {
    return {
      ownControl: false,
      title: '',
      description: '',
      companyId: '',
    }
  },
  async created() {
    this.title = `ADD TO FAVORITES: ${this.dest}`
    this.ownControl = true
    this.companyId = this.$route.params.id
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    async saveFavorite() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const data = await CompaniesService.saveFavorite({
              company_id: this.companyId,
              destination_account: this.dest,
              alias: this.description,
              created_by: this.currentUser.user_id,
            })
            if (data.status === 200) {
              this.$emit('reload')
              this.closeModal()
              this.showSuccessSwal('Account added to favorite list successfully!')
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',
    }),
  },
}
</script>

<style scoped>

</style>
