export default [
  {
    key: 'responsable',
    sortable: false,
    label: 'Responsable',
  },
  {
    key: 'origin_account',
    sortable: false,
    label: 'Origin account',
  },
  {
    key: 'destination_account',
    sortable: false,
    label: 'Destination account',
  },
  {
    key: 'amount',
    sortable: false,
    label: 'Amount',
  },

  {
    key: 'concept',
    sortable: false,
    label: 'Concept',
  },
  {
    key: 'date',
    sortable: false,
    label: 'Date',
  },
  {
    key: 'type',
    sortable: false,
    label: 'Type',
  },

  {
    key: 'status',
    sortable: false,
    label: 'Status',
  },

  {
    key: 'created_by',
    sortable: false,
    label: 'Created By',
  },

]
