<script src="../../create-schedule-transfer.pusher.js"></script>
<template>
  <div>

    <div class="d-flex justify-content-end" />

    <b-card
      no-body
      class="mb-2"
    >

      <filter-slot

        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="$refs['scheduleTransfer'].refresh()"
      >

        <b-table
          id="scheduleTransfer"
          slot="table"
          ref="scheduleTransfer"
          class="m-1"
          sticky-header="70vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :items="search"
          :fields="filteredFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"

          show-empty
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(responsable)="data">

            <div
              class="d-flex flex-column  justify-content-center align-items-start"
            >
              <span>
                {{ data.item.responsable_name }} {{ data.item.responsable_last_name }}

              </span></div>

          </template>
          <template v-slot:cell(origin_account)="data">

            <div
              class="d-flex flex-column  justify-content-center align-items-start"
            >
              <span>
                {{ data.item.origin_number_account }} - {{ data.item.origin_bank_name }}
              </span>

            </div>

          </template>

          <template v-slot:cell(destination_account)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p> {{ data.item.destination_account }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(amount)="data">

            <div
              class="d-flex flex-column  justify-content-center align-items-start"
            >
              <span>
                {{ formatPrice(data.item.amount) }}
              </span>

            </div>

          </template>
          <template v-slot:cell(concept)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p>  {{ data.item.concept }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(date)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p>  {{ data.item.schedule_date | myGlobal }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(type)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p>  {{ data.item.type }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(status)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <p
                :style="
                  data.item.status_number == 1 ? 'color: red;' : 'color: green;'
                "
              >
                {{ data.item.status }}
              </p>

            </div>

          </template>
          <template v-slot:cell(created_by)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <span>    {{ data.item.created_by_name }} {{ data.item.created_by_last_name }}</span>
                <span>  {{ data.item.created_at | myGlobalDay }}</span>
              </span>

            </div>

          </template>
          <template v-slot:cell(actions)="data">
            <div>
              <b-row>
                <feather-icon
                  v-if="
                    data.item.status_number != 2 &&
                      data.item.responsable == currentUser.user_id"
                  title="Complete"
                  icon="CheckIcon"
                  size="16"
                  class="cursor-pointer text-success ml-1"
                  @click="openComplete(data.item)"
                />
                <feather-icon
                  title="EDIT"
                  icon="EditIcon"

                  size="16"
                  class="cursor-pointer text-warning ml-1"
                  @click="openCreateTransferSchedule(data.item)"
                />
                <feather-icon
                  title="DELETED"
                  icon="Trash2Icon"
                  size="16"
                  class="cursor-pointer ml-1  text-danger"
                  @click="deleteScheduleTransfer(data.item)"
                />
              </b-row>
            </div>
          </template>
        </b-table>

      </filter-slot>

    </b-card>
    <complete

      v-if="completeOn"
      :id="idTransfer"
      @reload="$refs['scheduleTransfer'].refresh()"
      @close="closeComplete"
    />
    <create-transfer-schedule
      v-if="createTransferSchedule"
      :id-transfer-schedule="idTransferSchedule"
      :destination-account="destToSend"
      @reload="$refs['scheduleTransfer'].refresh()"
      @close="closeCreateTransferSchedule"
    />
    <favorite-list
      v-if="favoriteList"
      @close="closeFavoriteList"
      @selectedFavorite="openAndFillTransferModal"
    />
  </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FilterSlot from '@/views/crm/views/sales-made/components/slots/FilterSlot.vue'
import dataFields from '@/views/management/views/companies/dashboard/schedule-transfer-module/pending-list/fields.data'
import dataFilters from '@/views/management/views/companies/dashboard/schedule-transfer-module/pending-list/filters.data'
import CompaniesService from '@/views/management/views/companies/companies.service'
import Complete from '@/views/management/views/companies/dashboard/schedule-transfer-module/modals/Complete.vue'
import CreateTransferSchedule
from '@/views/management/views/companies/dashboard/schedule-transfer-module/modals/CreateTransferSchedule'
import FavoriteList from '@/views/management/views/companies/dashboard/schedule-transfer-module/modals/FavoriteList'

export default {
  components: {
    FilterSlot,
    Complete,
    CreateTransferSchedule,
    FavoriteList,
  },

  data() {
    return {
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      bankAccountModal: false,
      edit: false,
      companyInformation: [],
      checkAddButton: false,
      dataBankAccount: {},
      completeOn: false,
      idTransfer: null,
      createTransferSchedule: false,
      favoriteList: false,
      destToSend: '',
      idTransferSchedule: null,

    }
  },
  computed: {
    filteredFields() {
      return this.fields
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),
    ...mapState({

      managementUsers: state => state.ManagementCompaniesStore.S_MANAGEMENT_USERS,

    }),

  },

  async created() {
    try {
      await Promise.all([
        this.$store.dispatch('ManagementCompaniesStore/A_GET_MANAGEMENT_USERS'),

      ])
      this.filter[2].options = this.managementUsers
    } catch (error) {
      console.error(error)
    }
  },
  methods: {

    openCreateTransferSchedule(data) {
      this.createTransferSchedule = true
      this.idTransferSchedule = data.id
    },
    openFavoriteList() {
      this.favoriteList = true
    },

    closeCreateTransferSchedule() {
      this.createTransferSchedule = false
    },
    closeFavoriteList() {
      this.favoriteList = false
    },
    openAndFillTransferModal(dest) {
      this.createTransferSchedule = true
      this.destToSend = dest
    },

    openComplete(data) {
      this.completeOn = true
      this.idTransfer = data.id
    },
    closeComplete() {
      this.completeOn = false
    },
    resetAllFilters() {
      this.filter.forEach(filter => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.scheduleTransfer.refresh()
    },
    resetSearch() {
      this.searchInput = ''
      this.$refs.scheduleTransfer.refresh()
    },

    // eslint-disable-next-line consistent-return
    async deleteScheduleTransfer(data) {
      const params = {
        schedule_id: data.id,
        deleted_by: this.currentUser.user_id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await CompaniesService.deleteTransferSchedule(params)

          this.$refs.scheduleTransfer.refresh()

          this.showSuccessSwal('Transfer Schedule has been deleted successfully')
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async search(ctx) {
      try {
        const params = {
          perPage: this.paginate.perPage,
          orderby: 1,
          order: 'desc',
          from: this.filter[0].model,
          campo: this.filterPrincipal.model,
          to: this.filter[1].model,
          company_id: Number(this.$route.params.id),
          responsable: this.filter[2].model,
          status: 1,
        }
        const data = await CompaniesService.searchScheduleTransfer(params, ctx.currentPage)

        this.items = data.data.data
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

  },

}
</script>

<style scoped>

</style>
