<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    modal-class="modal-primary"
    :title="title"
    size="lg"
    @hidden="closeModal"
  >
    <div>
      <validation-observer ref="form">
        <b-row>
          <b-col md="6">
            <b-form-group
              id="responsable-1"
              label="Responsable"
              label-for="responsable-1"
            >
              <b-skeleton
                v-if="!finishedLoading"
                type="input"
              />
              <v-select
                v-if="finishedLoading"
                v-model="scheduleTransferObject.responsable"
                :options="managementUsers"
                label="user_name"
                :reduce="val => val.id"
              /></b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="origin_account-1"
              label="Origin account"
              label-for="origin_account-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <b-form-input
                v-if="finishedLoading"
                id="origin_account-1"
                v-model="scheduleTransferObject.origin_account_text"
                :disabled="true"

                trim
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="d-flex justify-content-between align-items-center">
              <label>Destination account</label>
              <feather-icon
                icon="ListIcon"
                size="17"
                class="ml-1 cursor-pointer text-primary"
                @click="favoriteListController = true"
              />
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <b-skeleton
                v-if="!finishedLoading"
                width="100%"
                type="input"
              />
              <b-form-input
                v-if="finishedLoading"
                id="destination_account-1"
                v-model="scheduleTransferObject.destination_account"
                style="width: 100%"
                trim
              />
              <feather-icon
                icon="StarIcon"
                style="color: #efb911; "
                :class="isRepeated? 'yellow-fill' : 'cursor-pointer'"
                size="20"
                class="ml-1 "
                @click="openAddToFavorites"
              />
            </div>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="currency-1"
              label="Currency"
              label-for="currency-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <v-select
                v-if="finishedLoading"
                v-model="scheduleTransferObject.currency"
                :options="currencies"
                label="currency"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              id="amount-1"
              label="Amount"
              label-for="amount-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <money
                v-if="finishedLoading "
                id="amount"
                v-model="scheduleTransferObject.amount"

                v-bind="money"
                class="form-control "
              />

            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="type-1"
              label="Type"
              label-for="type-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <v-select
                v-if="finishedLoading"
                v-model="scheduleTransferObject.type"
                :options="types"
                label="type"
                :reduce="val => val.id"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="date-1"
                  label="Date"
                  label-for="date-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <kendo-datepicker
                    v-if="finishedLoading"
                    v-model="scheduleTransferObject.date"
                    v-mask="'##/##/####'"
                    placeholder="Date"
                    :class="['w-100 rounded bg-transparent k-picker-custom', {'text-white':isDarkSkin}]"
                    :format="'MM/dd/yyyy'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="time-1"
                  label="Time"
                  label-for="time-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"

                    type="input"
                  />
                  <kendo-timepicker
                    v-if="finishedLoading"
                    v-model="scheduleTransferObject.time"
                    placeholder="Time"
                    :class="['w-100 rounded bg-transparent k-picker-custom', {'text-white':isDarkSkin}]"
                    :format="'HH:mm'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group
              id="concept-1"
              label="Concept"
              label-for="concept-1"
            >
              <b-skeleton
                v-if="!finishedLoading"

                type="input"
              />
              <b-form-textarea
                v-if="finishedLoading"
                id="concept-1"
                v-model="scheduleTransferObject.concept"

                trim
              />
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
    </div>
    <favorite-list
      v-if="favoriteListController"
      @close="favoriteListController = false"
      @selectedFavorite="setDestination"
    />
    <add-to-favorites
      v-if="addToFavoritesCont"
      :dest="scheduleTransferObject.destination_account"
      @close="addToFavoritesCont = false"
      @reload="getFavorites"
    />

    <template #modal-footer>
      <b-button
        variant="primary"
        @click="createTransferSchedule"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        SAVE
      </b-button>

    </template>
  </b-modal>
</template>

<script>

import vSelect from 'vue-select'
import { mapGetters, mapState } from 'vuex'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import FavoriteList from '@/views/management/views/companies-old/dashboard/schedule-transfer-module/modals/FavoriteList.vue'
import AddToFavorites from '@/views/management/views/companies-old/dashboard/schedule-transfer-module/modals/AddToFavorites.vue'

export default {
  components: {
    vSelect,
    FavoriteList,
    AddToFavorites,
  },

  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    selectedAccount: {
      type: String,
      default: null,
    },
    idTransferSchedule: {
      type: Number,
      default: null,
    },
    destinationAccount: {
      default: null,
    },
  },
  data() {
    return {

      amount: '',
      validAmount: false,
      spinnerOn: false,
      minDate: new Date(1000, 1, 1),
      maxDate: new Date(3000, 1, 1),
      destinationFromFavorite: '',
      favoriteListController: false,
      addToFavoritesCont: false,
      finishedLoading: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      title: '',
      scheduleTransferObject: {
        schedule_id: null,
        responsable: null,
        status: null,
        type: null,
        destination_account: null,
        amount: 0,
        currency: null,
        origin_account: null,
        origin_account_text: null,
        concept: null,
        date: null,
        time: null,
      },
      addToFavoritesObject: {
        destination_account: null,
        alias: null,
      },
      currencies: [
        {
          id: 1,
          currency: 'S/',
        },
        {
          id: 2,
          currency: '$',
        },
      ],
      types: [
        {
          id: 1,
          type: 'TRANSFER',
        },
        {
          id: 2,
          type: 'CHECK',
        },
      ],
      companyId: '',
      arrayBankAccounts: [],
      add_to_favorites: false,
      favoriteList: [],
      isRepeated: '',
    }
  },

  computed: {

    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),
    ...mapState({

      managementUsers: state => state.ManagementCompaniesStore.S_MANAGEMENT_USERS,

    }),
    money() {
      for (let i = 0; i < this.currencies.length; i++) {
        if (this.currencies[i].id === this.scheduleTransferObject.currency) {
          return {
            decimal: '.',
            thousands: ',',
            prefix: this.currencies[i].currency,
            precision: 2,
            maxlength: 14,
            masked: false,

          }
        }

        return {
          decimal: '.',
          thousands: ',',
          prefix: '',
          precision: 2,
          maxlength: 14,
          masked: false,
        }
      }
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'scheduleTransferObject.destination_account': function (val) {
      this.checkIfRepeated(val)
    },
    'scheduleTransferObject.currency': function (newVal) {
      this.setOriginAccount(newVal)
    },
    'scheduleTransferObject.amount': function (newVal, oldVal) {
      this.amount = this.scheduleTransferObject.amount
      if (this.idTransferSchedule) {
        this.scheduleTransferObject.amount = this.amount
      }
    },
  },
  async created() {
    this.getTitle()
    this.scheduleTransferObject.destination_account = this.destinationAccount

    this.ownControl = true

    try {
      await Promise.all([
        this.$store.dispatch('ManagementCompaniesStore/A_GET_MANAGEMENT_USERS'),
        this.getBankAccountAll(),
        this.getFavorites(),

      ])
    } catch (error) {
      console.error(error)
    }
    if (this.idTransferSchedule) {
      await this.getSchedule()
    }
    this.finishedLoading = true
  },
  async mounted() {
    if (this.selectedAccount) {
      this.scheduleTransferObject.destination_account = this.selectedAccount.destination_account
    }
  },
  methods: {
    calculateNewBalance(balance, incomes, expenses) {
      return Number(balance) + Number(incomes) - Number(expenses)
    },
    checkIfRepeated(val) {
      this.isRepeated = this.favoriteList.find(favorite => favorite.destination_account === val)
    },
    async getSchedule() {
      try {
        const data = await CompaniesService.getTransferShowById({
          schedule_id: this.idTransferSchedule,
        })
        const dataPrueba = data.data[0]
        this.amount = dataPrueba.amount
        this.scheduleTransferObject = dataPrueba

        this.$set(this.scheduleTransferObject, 'origin_account_text', null)
        if (data.status === 200) {
          this.finishedLoading = true
        }
        return this.scheduleTransferObject
      } catch (e) {
        this.showErrorSwal(e)
      }
    },

    getTitle() {
      if (this.idTransferSchedule) {
        this.title = 'UPDATE TRANSFER SCHEDULE'
      } else {
        this.title = 'CREATE TRANSFER SCHEDULE'
      }
    },
    closeModal() {
      this.$emit('close')
    },

    setDestination(dest) {
      this.scheduleTransferObject.destination_account = dest
    },
    async getFavorites() {
      try {
        const data = await CompaniesService.getFavorites({
          company_id: Number(this.$route.params.id),
        })
        this.favoriteList = data.data
        this.checkIfRepeated(this.scheduleTransferObject.destination_account)
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    // eslint-disable-next-line consistent-return
    async getBankAccountAll() {
      try {
        const params = {
          idcompany: Number(this.$route.params.id),
        }
        const data = await CompaniesService.getBankAccountsAll(params)

        this.arrayBankAccounts = data.data
        this.currencies = []
        for (let i = 0; i < this.arrayBankAccounts.length; i++) {
          const obj = {
            id: this.arrayBankAccounts[i].currency,
            currency: this.arrayBankAccounts[i].currency_text,
          }
          this.currencies.push(obj)
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async setOriginAccount(currency) {
      try {
        const params = {
          company_id: Number(this.$route.params.id),
          currency,
        }
        const data = await CompaniesService.getBankAccountByCurrency(params)

        if (data.data.length > 0) {
          this.scheduleTransferObject.origin_account = data.data[0].id
          this.scheduleTransferObject.origin_account_text = `${data.data[0].bank_name} - ${data.data[0].currency} ${this.calculateNewBalance(data.data[0].balance, data.data[0].total_incomes, data.data[0].total_expenses)}`
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    openAddToFavorites() {
      if (this.scheduleTransferObject.destination_account.length > 0 && !this.isRepeated) this.addToFavoritesCont = true
    },

    async createTransferSchedule() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()
        if (result) {
          const params = {
            schedule_id: this.idTransferSchedule,
            company_id: Number(this.$route.params.id),
            responsable: this.scheduleTransferObject.responsable,
            destination_account: this.scheduleTransferObject.destination_account,
            amount: this.scheduleTransferObject.amount,
            origin_account: this.scheduleTransferObject.origin_account,
            concept: this.scheduleTransferObject.concept,
            currency: this.scheduleTransferObject.currency,
            status: 1,
            type: this.scheduleTransferObject.type,
            created_by: this.currentUser.user_id,
            date: this.scheduleTransferObject.date,
            time: this.scheduleTransferObject.time,
          }

          const data = await CompaniesService.saveTransferSchedule(params)
          if (data.status === 200) {
            await this.$store.dispatch('ManagementCompaniesStore/A_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER', {
              company_id: Number(this.$route.params.id),
              responsable: this.currentUser.user_id,
            })
            this.$emit('reload')
            this.closeModal()
            this.showSuccessSwal('Transfer scheduled successfully!')
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },
}
</script>

<style >
input.k-input {
  padding: 0.5rem 1rem !important;
  height: auto !important;
}

.k-select {
  background-color: transparent !important;
  background-image: none !important;
}
.yellow-fill{
  fill: #efb911;
}
</style>
