<template>
  <b-modal
    v-model="ownControl"
    scrollable
    title-class="h3 text-white font-weight-bolder"
    title="CONFIRM SCHEDULED TRANSFER"
    size="md"
    @hidden="closeModal"
  >

    <validation-observer ref="form">
      <validation-provider
        v-slot="{ errors }"
        name="operation_number"
        rules="required"
      >
        <b-form-group
          id="operation_number-1"
          label="Operation number"
          label-for="operation_number-1"
        >
          <b-skeleton
            v-if="!finishedLoading"
            class="pr-1"

            type="input"
          />
          <b-form-input
            v-if="finishedLoading"
            id="operation_number-1"
            v-model="operationNumber"
            :state="errors[0] ? false : null"
            trim
          />
        </b-form-group>
      </validation-provider>
    </validation-observer>

    <template #modal-footer>
      <b-button
        variant="primary"
        @click="confirmScheduleTransfer"
      >
        <b-spinner
          v-if="spinnerOn"
          small
        />
        CONFIRM
      </b-button>

    </template>

  </b-modal>
</template>

<script>

import CompaniesService from '@/views/management/views/companies/companies.service'
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      finishedLoading: true,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      operationNumber: '',
      spinnerOn: false,

    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',

    }),

  },
  created() {
    this.ownControl = true
  },

  methods: {

    closeModal() {
      this.$emit('close')
    },
    async confirmScheduleTransfer() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()
        if (result) {
          const params = {
            schedule_id: this.id,
            operation_number: this.operationNumber,
          }

          const data = await CompaniesService.confirmScheduleTransfer(params)
          if (data.status === 200) {
            await this.$store.dispatch('ManagementCompaniesStore/A_MANAGEMENT_COUNTER_SCHEDULE_TRANSFER', {
              company_id: Number(this.$route.params.id),
              responsable: this.currentUser.user_id,
            })
            this.$emit('reload')
            this.showSuccessSwal()
            this.closeModal()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

  },
}
</script>

<style scoped>

</style>
