<template>
  <b-modal
    v-model="ownControl"
    scrollable
    modal-class="modal-primary"
    title-class="h3 text-white font-weight-bolder"
    title="FAVORITE LIST"
    size="md"
    hide-footer
    @hidden="closeModal"
  >
    <div v-if="!finishedLoading" class="text-center text-primary my-2">
      <b-spinner class="align-middle mr-1" />
      <strong>Loading ...</strong>
    </div>
    <h5 v-if="favoriteList.length === 0 && finishedLoading" class="text-center">You haven't added any favorite account yet</h5>
    <span v-if="favoriteList.length !== 0 && finishedLoading">
      <div
              v-for="(favorite, index) in favoriteList"
              :key="index"
          >
      <b-card
          class="cursor-pointer"
          :title="favorite.alias"
          @click="selectedFavorite(favorite)"
      >
        <div class="d-flex justify-content-between cursor-pointer">
          <b-card-text>
            {{ favorite.destination_account }}
          </b-card-text>
          <feather-icon
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="20"
              @click="deleteFavorite(favorite)"
          />
        </div>
      </b-card>
    </div>
    </span>
  </b-modal>
</template>

<script>

import CompaniesService from '@/views/management/views/companies-old/companies.service'

export default {
  data() {
    return {
      finishedLoading: false,
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      favoriteList: [],
      companyId: '',
      selFavorite: [],
    }
  },
  async created() {
    this.ownControl = true
    this.companyId = this.$route.params.id
    this.finishedLoading = false
    await this.getFavorites()
    this.finishedLoading = true
  },
  methods: {

    closeModal() {
      this.$emit('close')
    },
    // eslint-disable-next-line consistent-return
    async getFavorites() {
      try {
        const data = await CompaniesService.getFavorites({
          company_id: this.companyId,
        })
        this.favoriteList = data.data
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async deleteFavorite(item) {
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          const data = await CompaniesService.deleteFavorite({
            favorite_id: item.id,
          })
          if (data.status === 200) {
            this.showSuccessSwal('Success!', 'Account has been successfully removed from favorite list!')
            await this.getFavorites()
          }
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    selectedFavorite(favorite) {
      this.$emit('selectedFavorite', favorite.destination_account)
      this.closeModal()
    },
  },
}
</script>

<style scoped>

</style>
