<template>
  <div>

    <div class="d-flex justify-content-end" />

    <b-card
      no-body
      class="mb-2"
    >

      <filter-slot

        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="$refs['scheduleTransfer'].refresh()"
      >

        <b-table
          id="scheduleTransfer"
          slot="table"
          ref="scheduleTransfer"
          class="m-1"
          sticky-header="70vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :items="search"
          :fields="filteredFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          show-empty

          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(responsable)="data">

            <div
              class="d-flex flex-column  justify-content-center align-items-start"
            >
              <span>
                {{ data.item.responsable_name }} {{ data.item.responsable_last_name }}

              </span></div>

          </template>
          <template v-slot:cell(origin_account)="data">

            <div
              class="d-flex flex-column  justify-content-center align-items-start"
            >
              <span>
                {{ data.item.origin_number_account }} - {{ data.item.origin_bank_name }}
              </span>

            </div>

          </template>

          <template v-slot:cell(destination_account)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p> {{ data.item.destination_account }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(amount)="data">

            <div
              class="d-flex flex-column  justify-content-center align-items-start"
            >
              <span>
                {{ formatPrice(data.item.amount) }}
              </span>

            </div>

          </template>
          <template v-slot:cell(concept)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p>  {{ data.item.concept }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(date)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p>  {{ data.item.schedule_date }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(type)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <p>  {{ data.item.type }}</p>

              </span>

            </div>

          </template>
          <template v-slot:cell(status)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <p
                :style="
                  data.item.status_number == 1 ? 'color: red;' : 'color: green;'
                "
              >
                {{ data.item.status }}
              </p>

            </div>

          </template>
          <template v-slot:cell(created_by)="data">

            <div
              class="d-flex  flex-column  justify-content-center align-items-start"
            >
              <span>

                <span>    {{ data.item.created_by_name }} {{ data.item.created_by_last_name }}</span>
                <span>  {{ data.item.created_at | myGlobalDay }}</span>
              </span>

            </div>

          </template>

        </b-table>

      </filter-slot>

    </b-card>

  </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex'
import FilterSlot from '@/views/crm/views/sales-made/components/slots/FilterSlot.vue'
import dataFields from '@/views/management/views/companies-old/dashboard/schedule-transfer-module/done-list/fields.data'
import dataFilters from '@/views/management/views/companies-old/dashboard/schedule-transfer-module/done-list/filters.data'
import CompaniesService from '@/views/management/views/companies-old/companies.service'

export default {
  components: {
    FilterSlot,

  },

  data() {
    return {
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilters,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      bankAccountModal: false,
      edit: false,
      companyInformation: [],
      checkAddButton: false,
      dataBankAccount: {},
    }
  },
  computed: {
    filteredFields() {
      return this.fields
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),
    ...mapState({

      managementUsers: state => state.ManagementCompaniesStore.S_MANAGEMENT_USERS,

    }),

  },

  async created() {
    try {
      await Promise.all([
        this.$store.dispatch('ManagementCompaniesStore/A_GET_MANAGEMENT_USERS'),

      ])
      this.filter[2].options = this.managementUsers
    } catch (error) {
      console.error(error)
    }
  },
  methods: {

    resetAllFilters() {
      this.filter.forEach(filter => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.scheduleTransfer.refresh()
    },
    resetSearch() {
      this.searchInput = ''
      this.$refs.scheduleTransfer.refresh()
    },

    // eslint-disable-next-line consistent-return

    async search(ctx) {
      try {
        const params = {
          perPage: this.paginate.perPage,
          orderby: 1,
          order: 'desc',
          from: this.filter[0].model,
          campo: this.filterPrincipal.model,
          to: this.filter[1].model,
          company_id: Number(this.$route.params.id),
          responsable: this.filter[2].model,
          status: 2,
        }
        const data = await CompaniesService.searchScheduleTransfer(params, ctx.currentPage)

        this.items = data.data.data
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

  },

}
</script>

<style scoped>

</style>
