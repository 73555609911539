import { render, staticRenderFns } from "./PendingList.vue?vue&type=template&id=0fa31174&scoped=true"
import script from "./PendingList.vue?vue&type=script&lang=js"
export * from "./PendingList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa31174",
  null
  
)

export default component.exports