<template>
  <b-card class="border-top-primary border-3 border-table-radius px-0">
  <div>
    <create-transfer-schedule
      v-if="createTransferSchedule"
      :destination-account="destToSend"
      @close="closeCreateTransferSchedule"
      @reload="key++"
    />
    <favorite-list
      v-if="favoriteList"
      @close="closeFavoriteList"
      @selectedFavorite="openAndFillTransferModal"
    />
    <b-row class="justify-content-end mr-1">
      <b-button
        variant="success"
        @click="openFavoriteList"
      >FAVORITE LIST
      </b-button>
      <b-button
        variant="success"
        class="ml-1"
        @click="openCreateTransferSchedule"
      ><feather-icon
        icon="PlusIcon"
        size="15"
        class="mr-50 text-white"
      />CREATE
      </b-button>

    </b-row>

    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        exact
        :active="tab === 1"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="tab = 1"
      >PENDING
      </b-nav-item>
      <b-nav-item
        exact
        :active="tab === 2"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="tab = 2"
      >DONE
      </b-nav-item>

    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <pending-list
        v-if="tab===1"
        :key="key"
      />
      <done-list v-if="tab===2" />
    </b-card>

  </div>
  </b-card>
</template>

<script>

import CreateTransferSchedule
from '@/views/management/views/companies/dashboard/schedule-transfer-module/modals/CreateTransferSchedule.vue'
import FavoriteList from '@/views/management/views/companies/dashboard/schedule-transfer-module/modals/FavoriteList.vue'
import DoneList from '@/views/management/views/companies/dashboard/schedule-transfer-module/done-list/DoneList.vue'
import PendingList from '@/views/management/views/companies/dashboard/schedule-transfer-module/pending-list/PendingList.vue'

export default {
  components: {
    CreateTransferSchedule,
    FavoriteList,
    DoneList,
    PendingList,
  },
  data() {
    return {
      favoriteList: false,
      createTransferSchedule: false,
      destToSend: '',
      key: 1,
      tab: 1,
    }
  },
  methods: {
    openCreateTransferSchedule() {
      this.createTransferSchedule = true
    },
    openFavoriteList() {
      this.favoriteList = true
    },

    closeCreateTransferSchedule() {
      this.createTransferSchedule = false
    },
    closeFavoriteList() {
      this.favoriteList = false
    },
    openAndFillTransferModal(dest) {
      this.createTransferSchedule = true
      this.destToSend = dest
    },
  },

}
</script>

<style scoped>
</style>
