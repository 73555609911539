import { render, staticRenderFns } from "./ScheduleTransfer.vue?vue&type=template&id=fa2ed386&scoped=true"
import script from "./ScheduleTransfer.vue?vue&type=script&lang=js"
export * from "./ScheduleTransfer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa2ed386",
  null
  
)

export default component.exports